import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import logo from "./assets/img/logo_garex.png"
import logoPartner from "./assets/img/logo_santander_consumer.png"
import { useEffect, useState } from 'react';
import { SegHeaderLogo } from "./components/SegHeaderLogo/SegHeaderLogo"
import { SegFooter } from "./components/SegFooter/SegFooter"
import { DatosProducto } from './components/SubPages/DatosProducto';
import { DatosCliente } from './components/SubPages/DatosCliente';
import emitir from './services/Emitir';
import imprimir from './services/Imprimir';
import anular from './services/Anular';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Form, NavDropdown, Navbar, Spinner } from 'react-bootstrap';
import useStore from './stores/Store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { comercios, origenes, sucursales } from './services/Parametria';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export interface DatosProductoProps {
    productoTipo: string;
    productoMarca: string;
    productoModelo: string;
    productoPrecio: number;
    productoFactura: string;
    productoGarantiaFabrica: string;
    productoGarantiaExtendida: number;
}

export interface DatosClienteProps {
    tiendaVendedor: number;
    clienteNombre: string;
    clienteApellido: string;
    clienteTipoDoc: string;
    clienteDocumento: string;
    domicilioCalle: string;
    domicilioNumero: number;
    domicilioPiso: string;
    domicilioDepto: string;
    domicilioLocalidad: string;
    domicilioProvincia: string;
    domicilioCP: number;
}

export interface DatosModal {
    idGarex: number;
    exito: boolean;
    texto: string;
}

export interface DatosModalInformativo {
    titulo: string;
    texto: string;
}

export interface Origen {
    id: number;
    nombre: string;
    descripcion: string;
    activo: boolean;
    comercios: any[];
}

export interface Comercio {
    id: number;
    nombre: string;
    descripcion: string;
    activo: boolean;
    origenId: number;
    origen: any;
    sucursales: any[];
}

export interface Sucursal {
    id: number;
    numero: number;
    nombre: string;
    direccion: string;
    activo: boolean;
    comercioId: number;
    comercio: any[];
}

export interface ErrorCustom {
    campoNombre: string;
    error: string;
}

function App() {
    const [step, setStep] = useState(1);
    const [datosCliente, setDatosCliente] = useState<DatosClienteProps | null>(null)
    const [datosProducto, setDatosProducto] = useState<DatosProductoProps | null>(null)
    const [emitirSpinner, setEmitirSpinner] = useState(false);
    const [mostrarModalEmision, setMostrarModalEmision] = useState(false);
    const [mostrarModalInformativo, setMostrarModalInformativo] = useState(false);
    const [mostrarModalImpresion, setMostrarModalImpresion] = useState(false);
    const [mostrarModalAnular, setMostrarModalAnular] = useState(false);
    const [datosModal, setDatosModal] = useState<DatosModal | null>(null);
    const handleCloseEmision = () => setMostrarModalEmision(false);
    const handleCloseInformativo = () => setMostrarModalInformativo(false);
    const handleCloseImpresion = () => setMostrarModalImpresion(false);
    const handleOpenImpresion = () => setMostrarModalImpresion(true);
    const handleCloseAnular = () => setMostrarModalAnular(false);
    const handleOpenAnular = () => setMostrarModalAnular(true);
    const [cargandoImpresion, setCargandoImpresion] = useState(false);
    const [datosModalInformativo, setDatosModalInformativo] = useState<DatosModalInformativo | null>(null);
    const { datos, guardarDatos, limpiarDatos } = useStore();
    const { comercio, sucursal } = useParams();
    const navigate = useNavigate();
    const [errores, setErrores] = useState<ErrorCustom[]>([]);
    const [factura, setFactura] = useState<string>("");
    const [facturaTemp, setFacturaTemp] = useState<string>("");
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [cargandoGeneral, setCargandoGeneral] = useState(false);

    //Recaptcha key
    const site_key = process.env.REACT_APP_RECAPTCHA_KEY;

    const handleDownloadGarex = (base64PDF: string) => {
        // Decodificar Base64
        const byteCharacters = atob(base64PDF);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Crear un Blob con el contenido del PDF
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Crear un enlace para la descarga
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'garex-assist-santander.pdf'; // Nombre del archivo

        // Simular el clic en el enlace
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const emision = async (datosCliente: DatosClienteProps, datosProducto: DatosProductoProps) => {
        return await emitir(datosCliente, datosProducto, datos?.origen!, datos?.comercio!, datos?.sucursal!, captchaToken!);
    }

    const anularCerti = async (factura: string) => {
        setFacturaTemp("");
        if (factura) {
            setCargandoImpresion(true)
            try {
                anular(factura, datos?.origen.id!, datos?.comercio.id!).then((res: any) => {
                    setCargandoImpresion(false);
                    setMostrarModalAnular(false);
                    if (res.status === 200) {
                        setDatosModalInformativo({
                            titulo: "Anulación realizada",
                            texto: "Se anuló el certificado solicitado."
                        });
                        setMostrarModalInformativo(true);
                    } else {
                        setDatosModalInformativo({
                            titulo: "Error al anular",
                            texto: "El número de factura no fué encontrado."
                        });
                        setMostrarModalInformativo(true);
                    }
                })
            } catch (error) {
                setDatosModalInformativo({
                    titulo: "Error al anular",
                    texto: "Ocurrió un error inesperado."
                });
                setMostrarModalInformativo(true);
            } finally {
                setFactura("");
            }
        }
    }

    useEffect(() => {
        setCargandoGeneral(true);
        let location = window.location.hostname;
        let fragmentedLocation = [];
        if (location === "localhost") {
            fragmentedLocation = ["localhost"];
        } else {
            fragmentedLocation = location.split(".");
        }

        fragmentedLocation.forEach((loc: string) => {
            switch (loc.toLocaleLowerCase()) {
                case "qa":
                    guardarDatos({ ambiente: "QA" });
                    break;
                case "ut":
                    guardarDatos({ ambiente: "UT" });
                    fragmentedLocation[0] = process.env.REACT_APP_ORIGEN_MANUAL ?? "";
                    break;
                case "localhost":
                    guardarDatos({ ambiente: "DEV" });
                    fragmentedLocation[0] = process.env.REACT_APP_ORIGEN_MANUAL ?? "";
                    break;
                default:
                    guardarDatos({ ambiente: "PROD" });
                    break;
            }
        })
        if (datos?.origen.nombre.toLocaleLowerCase() !== fragmentedLocation[0].toLocaleLowerCase() ||
            datos?.comercio?.nombre.toLocaleLowerCase() !== comercio ||
            datos?.sucursal.numero.toString() !== sucursal) {
            validateParams(fragmentedLocation[0].toLocaleLowerCase() ?? "", comercio ?? "", sucursal ?? "")
                .then(valid => {
                    if (!valid) {
                        limpiarDatos();
                        navigate(`/${comercio}/${sucursal}/error`);
                    } else {
                        setCargandoGeneral(false);
                    }
                })
                .catch(error => {
                    console.log("Error: ", error)
                    navigate(`/${comercio}/${sucursal}/error`);
                    setCargandoGeneral(false);
                })
        } else {
            setCargandoGeneral(false);
        }
    }, [datos?.origen, datos?.comercio, datos?.sucursal, navigate]);

    const imprimirCertificado = async (factura: string, reimpresion: boolean = false) => {
        setFacturaTemp("");
        if (factura) {
            setCargandoImpresion(true)
            let response: any;
            imprimir(factura, datos?.origen.id!, datos?.comercio.id!).then((res: any) => {
                try {
                    response = res;
                    setCargandoImpresion(false);
                    setFactura("");
                    !reimpresion ? setMostrarModalEmision(false) : setMostrarModalImpresion(false);
                    var data = JSON.parse(res.data)
                    if (data.Success) {
                        handleDownloadGarex(data.Mensaje);
                        setDatosModalInformativo({
                            titulo: "Impresión realizada",
                            texto: "Descarga de documento exitosa."
                        });
                        setMostrarModalInformativo(true);
                    } else {
                        setDatosModalInformativo({
                            titulo: "Error al imprimir",
                            texto: "Hubo un error al generar el certificado." + data.Errores.join(' ')
                        });
                        setMostrarModalInformativo(true);
                    }
                } catch (error) {
                    try {
                        let errores = JSON.parse(response.response.data);
                        procesarErrores(errores.Errores);
                    } catch (error) {
                        setDatosModal({
                            exito: false,
                            idGarex: 0,
                            texto: "Hubo un error al imprimir la garantía."
                        })
                        setMostrarModalEmision(true);
                    }
                } finally {
                    reimpresion ?? setFactura("");
                }
            })

        }
    }

    useEffect(() => {
        if (datosCliente && datosProducto && captchaToken && emitirSpinner) {
            formEmitir();
        }
    }, [datosCliente, datosProducto, captchaToken, emitirSpinner])

    const formEmitir = () => {
        if (datosCliente !== null && datosProducto !== null  && captchaToken !== null && emitirSpinner) {
            emision(datosCliente, datosProducto).then((res: any) => {
                setEmitirSpinner(false)
                try {
                    var data = JSON.parse(res.data)
                    if (data.Success) {
                        setDatosModal({
                            exito: true,
                            idGarex: data.Id,
                            texto: "Se emitió la Garantía Extendida con éxito."
                        })
                        setFacturaTemp(datosProducto.productoFactura);
                        setDatosCliente(null);
                        setDatosProducto(null);
                        setStep(1);
                        setMostrarModalEmision(true);
                    } else {
                        try {
                            procesarErrores(data.Errores);
                        } catch (error) {
                            setDatosModal({
                                exito: false,
                                idGarex: 0,
                                texto: data.Errores.join(' ')
                            })
                            setMostrarModalEmision(true);
                        }
                    }
                } catch (error) {
                    try {
                        let errores = JSON.parse(res.response.data);
                        procesarErrores(errores.Errores);
                    } catch (error) {
                        setDatosModal({
                            exito: false,
                            idGarex: 0,
                            texto: "Hubo un error al emitir la Garantía Extendida."
                        })
                        setMostrarModalEmision(true);
                    }
                }
            });
        }
    }

    const procesarErrores = (erroresApi: string[]) => {
        erroresApi.forEach((err: any) => {
            switch (err) {
                case "Vendedor inválido":
                    setErrores([...errores, { campoNombre: "tiendaVendedor", error: "Vendedor inválido" }]);
                    setStep(1);
                    break;
                case "El valor del campo ArticuloGarantiaFabrica no es válido":
                    setErrores([...errores, { campoNombre: "productoGarantiaFabrica", error: "La cantidad de meses es incorrecta" }]);
                    break;
                default:
                    setDatosModal({
                        exito: false,
                        idGarex: 0,
                        texto: err
                    })
                    setMostrarModalEmision(true);
                    break;
            }
        });
    }

    const renderVista = () => {
        switch (step) {
            case 1:
                return (
                    <DatosCliente errores={errores} setStep={setStep} setDatosCliente={setDatosCliente} datosCliente={datosCliente} />
                )
            case 2:
                return (
                    <DatosProducto emitirSpinner={setEmitirSpinner} setCaptchaToken={setCaptchaToken} errores={errores} spinner={emitirSpinner} setStep={setStep} setDatosProducto={setDatosProducto} datosProducto={datosProducto} />
                )
        }
    }

    function validateParams(origen: string, comercio: string, sucursal: string) {
        return new Promise(async (resolve, reject) => {
            try {
                let origenList: Origen[] = (await origenes()).data;
                let origenSel: Origen = origenList.filter(x => x.nombre.toLocaleLowerCase() === origen.toLocaleLowerCase())[0];
                let comercioList: Comercio[] = (await comercios(origenSel.id)).data;
                let comercioSel: Comercio = comercioList.filter(x => x.nombre.toLocaleLowerCase() === comercio.toLocaleLowerCase())[0];
                let sucursalList: Sucursal[] = (await sucursales(comercioSel.id)).data;
                let sucursalSel: Sucursal = sucursalList.filter(x => x.numero.toString() === sucursal)[0];

                if (origenSel && comercioSel && sucursalSel) {
                    guardarDatos({ origen: origenSel, comercio: comercioSel, sucursal: sucursalSel })
                    resolve(true);
                } else {
                    resolve(false);
                }
            } catch (error) {
                resolve(false)
            }

        });
    };

    const handleBackNav = () => {
        if (step === 2) {
            setStep(1);
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Modal show={mostrarModalAnular} onHide={handleCloseAnular} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Anular certificado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="idReimpresion">
                            <Form.Label>Número de factura</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="00540000003456"
                                autoFocus
                                value={factura}
                                onChange={((e) => setFactura(e.target.value))}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!cargandoImpresion &&
                        <Button variant="secondary" onClick={handleCloseAnular}>
                            Cerrar
                        </Button>
                    }
                    <Button variant="primary" onClick={(e) => anularCerti(factura)} disabled={!factura}>
                        {!cargandoImpresion ?
                            "Anular"
                            :
                            <Spinner animation="border" />}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={mostrarModalImpresion} onHide={handleCloseImpresion} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Reimprimir certificado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="idReimpresion">
                            <Form.Label>Número de factura</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="00540000003456"
                                autoFocus
                                value={factura}
                                onChange={((e) => setFactura(e.target.value))}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!cargandoImpresion &&
                        <Button variant="secondary" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    }
                    <Button variant="primary" onClick={(e) => imprimirCertificado(factura, true)} disabled={!factura || cargandoImpresion}>
                        {!cargandoImpresion ?
                            "Reimprimir"
                            :
                            <Spinner animation="border" />}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={mostrarModalInformativo} onHide={handleCloseInformativo} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>{datosModalInformativo?.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{datosModalInformativo?.texto}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInformativo}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={mostrarModalEmision} onHide={handleCloseEmision} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Resultado de emisión</Modal.Title>
                </Modal.Header>
                <Modal.Body>{datosModal?.texto}</Modal.Body>
                <Modal.Footer>
                    {!cargandoImpresion &&
                        <Button variant="secondary" onClick={handleCloseEmision}>
                            Cerrar
                        </Button>
                    }
                    {datosModal?.exito && datosModal?.idGarex &&
                        <Button variant="primary" onClick={() => { imprimirCertificado(facturaTemp); }}>
                            {!cargandoImpresion ?
                                "Imprimir certificado"
                                :
                                <Spinner animation="border" />}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <div className="container sticky-top pt-3 pb-3 bg-white" style={{ maxWidth: "100%" }}>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                        <SegHeaderLogo img={logo} imgPartner={logoPartner} altPartner='Santander Consumer' alt="Garex Assist" width="160px" />
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "" }}>
                {cargandoGeneral ?
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spinner animation="border" />
                    </div>
                    :
                    <>
                        <Navbar className="bg-body-tertiary">
                            <Container>
                                <Navbar.Brand onClick={() => handleBackNav()} href="#">{step === 1 ? "Datos cliente" : "< Datos producto"}</Navbar.Brand>
                                <span className="badge text-bg-secondary">{datos?.comercio?.descripcion} Suc: {datos?.sucursal?.numero} ({datos?.sucursal?.nombre})</span>
                                <Navbar.Toggle />
                                <Navbar.Collapse className="justify-content-end">
                                    <NavDropdown title="Opciones" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="#" onClick={handleOpenImpresion}>Imprimir certificado</NavDropdown.Item>
                                        <NavDropdown.Item href="#" onClick={handleOpenAnular}>Anular garantía</NavDropdown.Item>
                                    </NavDropdown>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <GoogleReCaptchaProvider reCaptchaKey={site_key ?? ""}>
                            <div className="">{renderVista()}</div>
                        </GoogleReCaptchaProvider>
                    </>
                }
            </div >
            <div>
                <SegFooter
                    img={logo}
                    text='Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste, Buenos Aires, Argentina.'
                    linkAdicional="https://segurarse.com.ar/politicas-de-privacidad"
                    textAdicional="Políticas de Privacidad"
                />
            </div>
        </div >
    );
}

export default App;

