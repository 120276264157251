import Axios, { CancelTokenSource } from 'axios'
import { Comercio, DatosClienteProps, DatosProductoProps, Origen, Sucursal } from '../App';
import useStore from '../stores/Store';

interface Garex {
    Origen?: string;
    OrigenId?: number;
    Compania?: string;
    IdSistemaCliente?: string;
    TipoDocumentoPersona?: string;
    Email?: string;
    Telefono?: string;
    NroDocumento?: string;
    NombreCliente?: string;
    Comercio?: string;
    ComercioId?: number;
    Sucursal?: string;
    IdVendedor?: number;
    SucursalId?: number;
    ApellidoCliente?: string;
    DomicilioClienteCalle?: string;
    DomicilioClienteAltura?: number;
    DomicilioClienteCP?: number;
    DomicilioClienteCiudad?: string;
    DomicilioClienteProvincia?: string;
    DomicilioClientePais?: string;
    DomicilioClienteDpto?: string;
    DomicilioClientePiso?: string;
    ArticuloGrupo?: number;
    ArticuloTipo?: number;
    ArticuloMarca?: string;
    ArticuloModelo?: string;
    ArticuloDescripcion?: string;
    ArticuloPrecioArticulo?: string;
    ArticuloFechaCertificado?: string;
    ArticuloInicioVigencia?: string;
    ArticuloFinVigencia?: string;
    ArticuloFactura?: string;
    ArticuloSucursalFactura?: string;
    ArticuloGarantiaFabrica?: string;
    ArticuloGarantiaExtendida?: number;
    ArticuloCobertura?: number;
    ArticuloCoberturaAdicional?: number;
    CaptchaToken?: string;
}

const emitir = async (datosCliente: DatosClienteProps, datosProducto: DatosProductoProps, origen: Origen, comercio: Comercio, sucursal: Sucursal, captchaToken: string) => {
    const paramGarex: Garex = {
        Origen: origen.nombre,
        IdVendedor: datosCliente.tiendaVendedor,
        Sucursal: sucursal.nombre,
        SucursalId: sucursal.id,
        Comercio: comercio.nombre,
        ComercioId: comercio.id,
        OrigenId: origen.id,
        TipoDocumentoPersona: datosCliente.clienteTipoDoc,
        NroDocumento: datosCliente.clienteDocumento,
        NombreCliente: datosCliente.clienteNombre,
        ApellidoCliente: datosCliente.clienteApellido,
        DomicilioClienteCalle: datosCliente.domicilioCalle,
        DomicilioClienteAltura: datosCliente.domicilioNumero,
        DomicilioClienteCP: datosCliente.domicilioCP,
        DomicilioClienteCiudad: datosCliente.domicilioLocalidad,
        DomicilioClienteProvincia: datosCliente.domicilioProvincia,
        DomicilioClientePiso: datosCliente.domicilioPiso,
        DomicilioClienteDpto: datosCliente.domicilioDepto,
        ArticuloMarca: datosProducto.productoMarca,
        ArticuloModelo: datosProducto.productoModelo,
        ArticuloDescripcion: datosProducto.productoMarca + "-" + datosProducto.productoModelo,
        ArticuloPrecioArticulo: datosProducto.productoPrecio.toString(),
        ArticuloFactura: datosProducto.productoFactura,
        ArticuloGarantiaFabrica: datosProducto.productoGarantiaFabrica.toString(),
        ArticuloGarantiaExtendida: datosProducto.productoGarantiaExtendida,
        CaptchaToken: captchaToken
    }
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/garex", paramGarex,
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};

export default emitir;