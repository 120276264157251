import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { origenes, comercios, sucursales } from '../services/Parametria';
import App from '../App';
import Error from '../Error';
import useStore, { DatosIniciales } from '../stores/Store'; // Asegúrate de importar DatosIniciales si lo estás usando en otro lugar
import Login from '../pages/Login';

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/:comercio/:sucursal" element={<App />} />
                <Route path='/:comercio/:sucursal/error' element={<Error />} />
                <Route path='/admin' element={<Login />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
