import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SegSelect, SegSelectOption } from "../SegSelect/SegSelect";
import { DatosClienteProps, ErrorCustom } from "../../App";
import { SegInput } from "../SegInput/SegInput";
import { SegButton } from "../SegButton/SegButton";

interface FormValues {
    setStep: Dispatch<SetStateAction<number>>;
    setDatosCliente: Dispatch<SetStateAction<DatosClienteProps | null>>;
    datosCliente: DatosClienteProps | null,
    errores: ErrorCustom[]
}

export const DatosCliente = ({
    setStep,
    setDatosCliente,
    datosCliente,
    errores
}: FormValues) => {
    const { handleSubmit, control, setValue, formState, trigger, setError } = useForm<DatosClienteProps>();
    const methods = useForm<DatosClienteProps>();
    const [tipoDocumento, setTipoDocumento] = useState<SegSelectOption>();

    const opcionesTipoDocumento = [
        { value: "DNI", label: "DNI" },
        { value: "CUIT", label: "CUIT" }
    ];

    const onSubmit: SubmitHandler<DatosClienteProps> = (values: DatosClienteProps) => {
        setDatosCliente({ ...values });
        setStep(2);
    };

    useEffect(() => {
        setValue("tiendaVendedor", datosCliente?.tiendaVendedor ? datosCliente?.tiendaVendedor : 0)
        setValue("clienteNombre", datosCliente?.clienteNombre ? datosCliente?.clienteNombre : "")
        setValue("clienteApellido", datosCliente?.clienteApellido ? datosCliente?.clienteApellido : "")
        setValue("clienteDocumento", datosCliente?.clienteDocumento ? datosCliente?.clienteDocumento : "")
        setValue("domicilioCalle", datosCliente?.domicilioCalle ? datosCliente?.domicilioCalle : "")
        setValue("domicilioNumero", datosCliente?.domicilioNumero ? datosCliente?.domicilioNumero : 0)
        setValue("domicilioPiso", datosCliente?.domicilioPiso ? datosCliente?.domicilioPiso : "")
        setValue("domicilioDepto", datosCliente?.domicilioDepto ? datosCliente?.domicilioDepto : "")
        setValue("domicilioCP", datosCliente?.domicilioCP ? datosCliente?.domicilioCP : 0)
        setValue("domicilioProvincia", datosCliente?.domicilioProvincia ? datosCliente?.domicilioProvincia : "")
        setValue("domicilioLocalidad", datosCliente?.domicilioLocalidad ? datosCliente?.domicilioLocalidad : "")
        if (datosCliente?.clienteTipoDoc) {
            setTipoDocumento({value: datosCliente?.clienteTipoDoc, label: datosCliente?.clienteTipoDoc});
        }
    }, []);

    useEffect(() => {
        setValue('clienteTipoDoc', tipoDocumento?.value ?? "", { shouldValidate: true })
    }, [tipoDocumento])

    useEffect(() => {
        errores.forEach(element => {
            switch (element.campoNombre) {
                case "tiendaVendedor":
                    setError(element.campoNombre, {
                        type: "manual",
                        message: element.error,
                    });
                    break;
                default:
                    break;
            }
        });
    }, [errores])

    return (
        <div className="bg-gray-light">
            <div className="container">
                <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <Controller
                            name="tiendaVendedor"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el el numero de vendedor"
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Vendedor"
                                    name="tiendaVendedor"
                                    type="number"
                                    width={50}
                                    placeHolder="Ej: 234"
                                    autoFocus={true}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("tiendaVendedor", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("tiendaVendedor");
                                    }}
                                    value={field.value ? field.value.toString() : ""}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.tiendaVendedor
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.tiendaVendedor
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="clienteNombre"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el nombre",
                                    pattern: (value) =>
                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/.test(value) ||
                                        "Nombre inválido",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Nombre"
                                    name="clienteNombre"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Juan"
                                    autoFocus={true}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("clienteNombre", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("clienteNombre");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.clienteNombre
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.clienteNombre
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="clienteApellido"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el apellido",
                                    pattern: (value) =>
                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/.test(value) ||
                                        "Apellido inválido",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Apellido"
                                    name="apellido"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Lopez"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("clienteApellido", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("clienteApellido");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.clienteApellido
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.clienteApellido
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset style={{ width: "50%", margin: "0 auto", display: "flex", flexDirection: "row", placeContent: "space-between" }}>
                        <FormProvider {...methods}>
                            <div>
                                <label className='mb-2'>Tipo de documento</label>
                                <SegSelect
                                    width='100%'
                                    selected={tipoDocumento}
                                    setSelected={setTipoDocumento}
                                    name="clienteTipoDoc"
                                    options={opcionesTipoDocumento}
                                    borderColor={`${formState.errors.clienteTipoDoc
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.clienteTipoDoc
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                    openDefault={false}
                                    buscador={false}
                                    placeholder='Seleccionar'
                                />
                            </div>
                        </FormProvider>
                        <Controller
                            name="clienteDocumento"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el documento",
                                    pattern: (value) =>
                                        /^\d{1,11}$/.test(value) ||
                                        "Ingresá el documento",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mb-4 ml-4"
                                    label="Número documento"
                                    name="clienteDocumento"
                                    type="text"
                                    width={66}
                                    placeHolder="Ej: 2"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("clienteDocumento", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("clienteDocumento");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.clienteDocumento
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.clienteDocumento
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="domicilioCalle"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá la calle",
                                    pattern: (value) =>
                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/.test(value) ||
                                        "Calle inválida",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Calle de domicilio"
                                    name="domicilioCalle"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Av. Santa Fé"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioCalle", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioCalle");
                                    }}
                                    value={field.value} leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioCalle
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioCalle
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="domicilioNumero"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el el numero del domicilio"
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Numero casa"
                                    name="domicilioNumero"
                                    type="number"
                                    width={50}
                                    value={field.value ? field.value.toString() : undefined}
                                    placeHolder="Ej: 7656"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioNumero", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioNumero");
                                    }}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioNumero
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioNumero
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset style={{ width: "50%", margin: "0 auto", display: "flex", flexDirection: "row", placeContent: "space-between" }}>
                        <Controller
                            name="domicilioPiso"
                            control={control}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mb-4"
                                    label="Piso"
                                    name="domicilioPiso"
                                    type="text"
                                    width={48}
                                    placeHolder="Ej: 2"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioPiso", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioPiso");
                                    }}
                                    value={field.value} leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioPiso
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioPiso
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                        <Controller
                            name="domicilioDepto"
                            control={control}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mb-4 "
                                    label="Departamento"
                                    name="domicilioDpto"
                                    type="text"
                                    width={48}
                                    placeHolder="Ej: D"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioDepto", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioDepto");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioDepto
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioDepto
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="domicilioCP"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el código postal",
                                    pattern: (value) =>
                                        /^\d{1,4}$/.test(value.toString()) ||
                                        "Ingresá el código postal",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Código postal"
                                    name="domicilioCP"
                                    type="text"
                                    width={50}
                                    value={field.value ? field.value.toString() : undefined}
                                    placeHolder="Ej: 7656"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioCP", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioCP");
                                    }}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioCP
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioCP
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="domicilioProvincia"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá la provincia",
                                    pattern: (value) =>
                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/.test(value) ||
                                        "Provincia inválida",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Provincia"
                                    name="domicilioProvincia"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Buenos Aires"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioProvincia", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioProvincia");
                                    }}
                                    value={field.value} leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioProvincia
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioProvincia
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="domicilioLocalidad"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá la localidad",
                                    pattern: (value) =>
                                        /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/.test(value) ||
                                        "Localidad inválida",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Localidad"
                                    name="domicilioLocalidad"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Olivos"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("domicilioLocalidad", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("domicilioLocalidad");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.domicilioLocalidad
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.domicilioLocalidad
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <div className="mx-auto mb-4" style={{ width: "172px" }}>
                        <SegButton
                            label="Continuar"
                            type="submit"
                            className="btn btn-primary"
                            disabled={
                                !formState.isValid // Verifica si el formulario es válido
                            }
                        />
                    </div>
                </form>
            </div>
        </div >
    )
};